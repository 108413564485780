<template>
  <div class="animate-fade-in-down">    
    <div 
    @toggleDarkMode="toggleDarkMode" 
    :class="{ 'text-teal-50': isDarkMode, 'text-gray-800': !isDarkMode }"
    class="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 sm:px-6 sm:py-12 lg:max-w-6xl lg:grid-cols-2 lg:px-8">
      <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-0 md:py-16 animate-fade-in-down">
        <card-double v-for="(card, index) in cards" 
          :key ="index" 
          :title="card.title" 
          :contentHtml="card.contentHtml"
          :width="card.width"
          :height="card.height"/>
        </div>   
      </div>
  </div>
</template>



<script setup> 
// import IconSvg from "@/elements/IconSvg.vue";
import CardDouble from '@/elements/CardDouble.vue';
import { useDark } from "@vueuse/core";

const isDarkMode = useDark();

const toggleDarkMode = () => {
  isDarkMode.value = !isDarkMode.value;
};

const cards = [
  {
    title: 'My Test',
    contentHtml: `<p>"I once took a test at a company during their evaluation process. The test covered different aspects relevant to the position I was applying for, such as technical skills and problem-solving. It was an opportunity for me to demonstrate my abilities and suitability for the role."</p>
    <div class="flex items-center justify-between p-2">
      <a class="hover:bg-teal-500 px-2 py-1 rounded-md" href="https://drive.google.com/file/d/17xKG5kfpZNXG4evQU3ue_Z9qcH63ARqO/view?usp=sharing" target="_blank">See test</a>
      <a class="hover:bg-teal-500 px-2 py-1 rounded-md"href="https://bti-test-a6895.web.app" target="_blank">see answer</a>
    </div>`,
    width: '220',
    height: '340' 
  }, 
];

</script>
