import { createRouter, createWebHistory } from 'vue-router'; 
import Login from '@/pages/LoginPage.vue';
import SignUp from '@/pages/SignUpPage.vue';
import Home from '@/pages/HomePage.vue';
import About from '@/pages/AboutPage.vue';
import WelcomePage from '@/pages/WelcomePage.vue';
import PreventivePage from '@/pages/PreventivePage.vue';
import CorrectivePage from '@/pages/CorrectivePage.vue';
import BanknoteCounter from '@/pages/BanknoteCounter.vue';
import BanknoteDetection from '@/pages/BanknoteDetection.vue';
import BanknoteSorting from '@/pages/BanknoteSorting.vue';
import CurrencyPage from '@/pages/CurrencyPage.vue';
import TestPage from '@/pages/TestPage.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/home', component: Home },
  { path: '/login', component: Login },
  { path: '/mirda-portofolio', component: WelcomePage },
  { path: '/sign-up', component: SignUp },
  { path: '/about', component: About },
  { path: '/preventive-page', component: PreventivePage },
  { path: '/corrective-page', component: CorrectivePage },
  { path: '/banknote-counter', component: BanknoteCounter },
  { path: '/banknote-detection', component: BanknoteDetection },
  { path: '/banknote-sorting', component: BanknoteSorting },
  { path: '/currency-page', component: CurrencyPage },
  { path: '/test-page', component: TestPage },
  // Tambahkan aturan rute khusus untuk mengarahkan /portofolio ke halaman utama
  { path: '/portofolio', redirect: '/' },
  // Tambahkan aturan rute khusus untuk menangani halaman tidak ditemukan
  { path: '/:pathMatch(.*)', redirect: '/' } // Mengarahkan semua rute yang tidak cocok ke halaman utama
]; 

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Cek apakah pengguna mengakses akar domain
  if (to.path === '/') {
    const lastVisitedPage = sessionStorage.getItem('lastVisitedPage');
    // Jika ada halaman terakhir yang diakses, arahkan pengguna ke halaman tersebut
    if (lastVisitedPage) {
      next(lastVisitedPage);
    } else {
      // Jika tidak ada halaman terakhir yang diakses, arahkan ke halaman utama
      next();
    }
  } else {
    // Jika pengguna mengakses rute lain, simpan informasi tentang halaman tersebut
    sessionStorage.setItem('lastVisitedPage', to.fullPath);
    next();
  }
});

export default router;
