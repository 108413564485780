// main.js
import { createApp } from 'vue';
import '@/assets/styles.css'; 
import App from '@/App.vue';
import axios from 'axios';
import router from '@/router';
import LottiePlayer from 'lottie-web';  
 
// Membuat instance aplikasi Vue
const vueApp = createApp(App);

// Menambahkan instance axios ke dalam properti global aplikasi Vue
vueApp.config.globalProperties.$axios = axios;

// Menggunakan router
vueApp.use(router);

// Mendaftarkan LottiePlayer sebagai komponen global
vueApp.component('lottie-player', LottiePlayer);

// Mount aplikasi ke dalam elemen dengan id 'app'
vueApp.mount('#app');
